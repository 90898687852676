import React, { Component } from "react";
import styles from "./PlayerIcon.module.css";

export class PlayerIcon extends Component {
    render() {
        return (
            <div className={styles.iconContainer}>
                <img
                    src={`images/head-${this.props.player.headIconId}.png`}
                    alt="Player Head"
                    className={styles.icon}
                />
                <img
                    src={`images/body-${this.props.player.bodyIconId}.png`}
                    alt="Player Body"
                    className={styles.icon}
                />
            </div>
        );
    }
}
