import React, { Component } from "react";
import { Logo } from "../logo/Logo.js";
import styles from "./EndScore.module.css";
import { PlayerIcon } from "../playerIcon/PlayerIcon.js";
import { GameCode } from "../gameCode/GameCode";

export class EndScore extends Component {
    render() {
        return (
            <div className={styles.content}>
                <div className={styles.logoRow}>
                    <Logo />
                    <h1 className={styles.title}>Best Sellers</h1>
                    <div className={styles.spacer}></div>
                </div>
                <div className={styles.playerList}>
                    {this.props.game.players
                        .sort((a, b) => a.score < b.score ? 1 : -1)
                        .map((player, index) => {
                        return (
                            <div className={styles.playerStats}>
                                <span className={styles.rank}>{index+1}</span>
                                <div className={`icon-med ${styles.icon}`}>
                                    <PlayerIcon player={player} />
                                </div>
                                <p className={styles.playerName}>{player.name}</p>
                                <p className={styles.playerScore}>
                                    <strong>{player.score}</strong> Copies Sold
                                </p>
                            </div>
                        )
                    })}
                </div>
                <div className={styles.gameCodeRow}>
                    <GameCode gameCode="XYZZ" />
                </div>
            </div>
        );
    }
}
