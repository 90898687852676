import React, { Component } from "react";
import styles from "./Waiting.module.css";
import { Logo } from "../logo/Logo.js";
import { WaitAnimation } from "../waitAnimation/WaitAnimation.js";
import { GameCode } from "../gameCode/GameCode.js";
import { QuitGameButton } from "../quitGame/QuitGameButton.js";

export class Waiting extends Component {
    render() {
        let waitingText = "Waiting for the others"

        if (this.props.waitingText) {
            waitingText = this.props.waitingText;
        }

        return (
            <div className={styles.content}>
                <div className={`logo-row-player`}>
                    <Logo />
                    <QuitGameButton handleQuitGame={this.props.handleQuitGame} />
                </div>
                <div className={styles.animationContainer}>
                    <WaitAnimation className={styles.animation} />
                    <h2>{waitingText}...</h2>
                </div>
                <div className={styles.gameCodeRow}>
                    <GameCode gameCode={this.props.gameCode} />
                </div>
            </div>
        );
    }
}
