import React, { Component } from "react";
import { OpenQuote, CloseQuote } from "../quoteMarks/QuoteMarks.js";
import { Logo } from "../logo/Logo.js";
import styles from "./AnsweringPrompt.module.css";
import { GameCode } from "../gameCode/GameCode.js";
import { QuitGameButton } from "../quitGame/QuitGameButton";
import { Waiting } from "./Waiting.js";

export class AnsweringPrompt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCharCount: "128",
            answerVal: "",
        };
    }

    handleKeyStroke = (event) => {
        this.handleAnswerInputChange(event);
        this.handleResizeTextarea(event);
    };

    handleAnswerInputChange = (event) => {
        const answerVal = event.target.value;
        const inputLength = event.target.value.length;
        let charCount = 128 - inputLength;

        if (inputLength > 128) {
            this.setState({
                nameCharCount: 0,
            });
        } else {
            this.setState({
                nameCharCount: charCount,
                answerVal: answerVal,
            });
        }
    };

    handleSubmitAnswer = (event) => {
        event.preventDefault();

        if (this.state.answerVal.length > 0) {
            this.props.handleSubmitResponse(this.state.answerVal);
        }
    };

    handleResizeTextarea(e) {
        e.target.style.height = "inherit";
        e.target.style.height = `calc(${e.target.scrollHeight}px + 6px)`;
    };

    getCriticScreen = () => {
        return <Waiting
            waitingText={"You are the critic. Wait for everyone to respond."}
            gameCode={this.props.gameCode}
            handleQuitGame={this.props.handleQuitGame} />;
    };

    getPlayerScreen = () => {
        return (
            <>
                <div className={styles.content}>
                    <div className={`logo-row-player`}>
                        <Logo />
                        <QuitGameButton handleQuitGame={this.props.handleQuitGame} />
                    </div>
                    <div className={styles.promptAnswer}>
                        <div className={styles.prompt}>
                            <h2 className={styles.promptText}>
                                {this.props.prompt.text}
                            </h2>
                        </div>
                        <form
                            onSubmit={this.handleSubmitAnswer}
                            className={styles.form}
                        >
                            <div className={styles.textareaContainer}>
                                <OpenQuote
                                    className={`${styles.quote} ${styles.open}`}
                                />
                                <div className={styles.textareaSpan}>
                                    <span>{this.state.nameCharCount}</span>
                                    <textarea
                                        value={this.state.answerVal}
                                        className={styles.textarea}
                                        onChange={this.handleKeyStroke}
                                        placeholder={`What does ${this.props.prompt.criticPlayerName} say?`}
                                    />
                                </div>
                                <CloseQuote
                                    className={`${styles.quote} ${styles.close}`}
                                />
                            </div>
                            <button className={`${styles.btn} button button-sm`} type="submit">
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </>
        );
    };

    render() {
        let displayContent;

        if (this.props.player.id === this.props.prompt.criticPlayerId) {
            displayContent = this.getCriticScreen();
        } else {
            displayContent = this.getPlayerScreen();
        }

        return displayContent;
    }
}
