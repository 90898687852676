export function getGameData() {
    return {
        id: "48ece274-9717-44d1-9982-20785fa22b31",
        hostConnectionId: "maHhX9jzZUPRXvrIYRA1sw",
        gameCode: "XYZZ",
        gameState: 3,
        players: [
            {
                id: "7d301d9c-1cf0-4129-bde2-136e0f8e2f42",
                connectionId: "2KbUjlE4ooMdHicr7BV2Ag",
                gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                playerState: 5,
                name: "Player 1",
                score: 0,
                headIconId: 0,
                bodyIconId: 0,
            },
            {
                id: "85ab885a-b6c2-4e7b-a2bb-6601e7082b3b",
                connectionId: "T0Mh2uPXc6UZ9-eRS85rXg",
                gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                playerState: 5,
                name: "Player 2",
                score: 100,
                headIconId: 0,
                bodyIconId: 0,
            },
            {
                id: "e5ec5a17-bea3-4d21-b1bd-27a0375f85cb",
                connectionId: "MSV_1pR1D6dwplFQqDo6eA",
                gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                playerState: 5,
                name: "Player 3",
                score: 200,
                headIconId: 0,
                bodyIconId: 0,
            },
        ],
        prompts: [
            {
                id: "c7471d83-d404-4478-99ba-be2fb4c17d1d",
                gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                criticPlayerId: "7d301d9c-1cf0-4129-bde2-136e0f8e2f42",
                criticPlayerName: "Player 1",
                text: "Player 1 plays with animated puzzle pieces. They make funny pictures, and Player 1 tries to put them together in silly ways.",
                category: 0,
                hasWinner: true,
                responses: [
                    {
                        id: "dff29d69-3756-4407-8917-6c9001b7aab41",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "c7471d83-d404-4478-99ba-be2fb4c17d1d",
                        playerId: "85ab885a-b6c2-4e7b-a2bb-6601e7082b3b",
                        text: "WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW",
                        votes: 0,
                    },
                    {
                        id: "23cb8d08-c8bc-4dc6-85c7-978c7fcc44952",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "c7471d83-d404-4478-99ba-be2fb4c17d1d",
                        playerId: "e5ec5a17-bea3-4d21-b1bd-27a0375f85cb",
                        text: "WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW",
                        votes: 1,
                    },
                    {
                        id: "dff29d69-3756-4407-8917-6c9001b7aab43",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "c7471d83-d404-4478-99ba-be2fb4c17d1d",
                        playerId: "e5ec5a17-bea3-4d21-b1bd-27a0375f85cb",
                        text: "WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW",
                        votes: 0,
                    },
                    {
                        id: "dff29d69-3756-4407-8917-6c9001b7aab44",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "c7471d83-d404-4478-99ba-be2fb4c17d1d",
                        playerId: "e5ec5a17-bea3-4d21-b1bd-27a0375f85cb",
                        text: "WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW",
                        votes: 0,
                    },
                    {
                        id: "dff29d69-3756-4407-8917-6c9001b7aab45",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "c7471d83-d404-4478-99ba-be2fb4c17d1d",
                        playerId: "85ab885a-b6c2-4e7b-a2bb-6601e7082b3b",
                        text: "WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW",
                        votes: 0,
                    },
                    {
                        id: "23cb8d08-c8bc-4dc6-85c7-978c7fcc44956",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "c7471d83-d404-4478-99ba-be2fb4c17d1d",
                        playerId: "e5ec5a17-bea3-4d21-b1bd-27a0375f85cb",
                        text: "Player 3's Test Response Player 3's Test Response Player 3's Test Response",
                        votes: 1,
                    },
                    {
                        id: "dff29d69-3756-4407-8917-6c9001b7aab47",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "c7471d83-d404-4478-99ba-be2fb4c17d1d",
                        playerId: "e5ec5a17-bea3-4d21-b1bd-27a0375f85cb",
                        text: "WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW",
                        votes: 0,
                    },
                    {
                        id: "dff29d69-3756-4407-8917-6c9001b7aab48",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "c7471d83-d404-4478-99ba-be2fb4c17d1d",
                        playerId: "e5ec5a17-bea3-4d21-b1bd-27a0375f85cb",
                        text: "WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW",
                        votes: 0,
                    },
                ],
            },
            {
                id: "d0c546c6-6385-47ca-9769-88c44ba0441c",
                gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                criticPlayerId: "85ab885a-b6c2-4e7b-a2bb-6601e7082b3b",
                criticPlayerName: "Player 2",
                text: "Player 2 bounces through a psychedelic landscape on a pogo stick, encountering bouncing creatures with a knack for wordplay.",
                category: 0,
                responses: [
                    {
                        id: "1cc1e681-ec74-49ea-b493-8e4f01a68df5",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "d0c546c6-6385-47ca-9769-88c44ba0441c",
                        playerId: "7d301d9c-1cf0-4129-bde2-136e0f8e2f42",
                        text: "Player 1's Test Response",
                        votes: 0,
                    },
                    {
                        id: "73246d67-515f-41fc-a89d-eecb602665c4",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "d0c546c6-6385-47ca-9769-88c44ba0441c",
                        playerId: "e5ec5a17-bea3-4d21-b1bd-27a0375f85cb",
                        text: "Player 3's Test Response",
                        votes: 1,
                    },
                ],
            },
            {
                id: "0344cfc0-bd69-4f05-b476-98fef4180ae9",
                gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                criticPlayerId: "e5ec5a17-bea3-4d21-b1bd-27a0375f85cb",
                criticPlayerName: "Player 3",
                text: "Player 3 is on a planet made of cupcakes, and mischievous frosting creatures challenge him to a dessert duel.",
                category: 0,
                responses: [
                    {
                        id: "064b1d47-bfa9-41bd-8067-37e2bf68f474",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "0344cfc0-bd69-4f05-b476-98fef4180ae9",
                        playerId: "85ab885a-b6c2-4e7b-a2bb-6601e7082b3b",
                        text: "Player 2's Test Response",
                        votes: 1,
                    },
                    {
                        id: "5a5450f8-0ae8-4e84-89d1-78155818f3d5",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "0344cfc0-bd69-4f05-b476-98fef4180ae9",
                        playerId: "7d301d9c-1cf0-4129-bde2-136e0f8e2f42",
                        text: "Player 1's Test Response",
                        votes: 0,
                    },
                    {
                        id: "064b1d47-bfa9-41bd-8067-37e2bf68f474",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "0344cfc0-bd69-4f05-b476-98fef4180ae9",
                        playerId: "85ab885a-b6c2-4e7b-a2bb-6601e7082b3b",
                        text: "Player 2's Test Response",
                        votes: 1,
                    },
                    {
                        id: "5a5450f8-0ae8-4e84-89d1-78155818f3d5",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "0344cfc0-bd69-4f05-b476-98fef4180ae9",
                        playerId: "7d301d9c-1cf0-4129-bde2-136e0f8e2f42",
                        text: "Player 1's Test Response",
                        votes: 0,
                    },
                    {
                        id: "064b1d47-bfa9-41bd-8067-37e2bf68f474",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "0344cfc0-bd69-4f05-b476-98fef4180ae9",
                        playerId: "85ab885a-b6c2-4e7b-a2bb-6601e7082b3b",
                        text: "Player 2's Test Response",
                        votes: 1,
                    },
                    {
                        id: "5a5450f8-0ae8-4e84-89d1-78155818f3d5",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "0344cfc0-bd69-4f05-b476-98fef4180ae9",
                        playerId: "7d301d9c-1cf0-4129-bde2-136e0f8e2f42",
                        text: "Player 1's Test Response",
                        votes: 0,
                    },
                    {
                        id: "064b1d47-bfa9-41bd-8067-37e2bf68f474",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "0344cfc0-bd69-4f05-b476-98fef4180ae9",
                        playerId: "85ab885a-b6c2-4e7b-a2bb-6601e7082b3b",
                        text: "Player 2's Test Response",
                        votes: 1,
                    },
                    {
                        id: "5a5450f8-0ae8-4e84-89d1-78155818f3d5",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "0344cfc0-bd69-4f05-b476-98fef4180ae9",
                        playerId: "7d301d9c-1cf0-4129-bde2-136e0f8e2f42",
                        text: "Player 1's Test Response",
                        votes: 0,
                    },
                    {
                        id: "064b1d47-bfa9-41bd-8067-37e2bf68f474",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "0344cfc0-bd69-4f05-b476-98fef4180ae9",
                        playerId: "85ab885a-b6c2-4e7b-a2bb-6601e7082b3b",
                        text: "Player 2's Test Response",
                        votes: 1,
                    },
                    {
                        id: "5a5450f8-0ae8-4e84-89d1-78155818f3d5",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "0344cfc0-bd69-4f05-b476-98fef4180ae9",
                        playerId: "7d301d9c-1cf0-4129-bde2-136e0f8e2f42",
                        text: "Player 1's Test Response",
                        votes: 0,
                    },
                    {
                        id: "064b1d47-bfa9-41bd-8067-37e2bf68f474",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "0344cfc0-bd69-4f05-b476-98fef4180ae9",
                        playerId: "85ab885a-b6c2-4e7b-a2bb-6601e7082b3b",
                        text: "Player 2's Test Response",
                        votes: 1,
                    },
                    {
                        id: "5a5450f8-0ae8-4e84-89d1-78155818f3d5",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "0344cfc0-bd69-4f05-b476-98fef4180ae9",
                        playerId: "7d301d9c-1cf0-4129-bde2-136e0f8e2f42",
                        text: "Player 1's Test Response",
                        votes: 0,
                    },
                    {
                        id: "064b1d47-bfa9-41bd-8067-37e2bf68f474",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "0344cfc0-bd69-4f05-b476-98fef4180ae9",
                        playerId: "85ab885a-b6c2-4e7b-a2bb-6601e7082b3b",
                        text: "Player 2's Test Response",
                        votes: 1,
                    },
                    {
                        id: "5a5450f8-0ae8-4e84-89d1-78155818f3d5",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "0344cfc0-bd69-4f05-b476-98fef4180ae9",
                        playerId: "7d301d9c-1cf0-4129-bde2-136e0f8e2f42",
                        text: "Player 1's Test Response",
                        votes: 0,
                    },
                    {
                        id: "064b1d47-bfa9-41bd-8067-37e2bf68f474",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "0344cfc0-bd69-4f05-b476-98fef4180ae9",
                        playerId: "85ab885a-b6c2-4e7b-a2bb-6601e7082b3b",
                        text: "Player 2's Test Response",
                        votes: 1,
                    },
                    {
                        id: "5a5450f8-0ae8-4e84-89d1-78155818f3d5",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "0344cfc0-bd69-4f05-b476-98fef4180ae9",
                        playerId: "7d301d9c-1cf0-4129-bde2-136e0f8e2f42",
                        text: "Player 1's Test Response",
                        votes: 0,
                    },
                    {
                        id: "064b1d47-bfa9-41bd-8067-37e2bf68f474",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "0344cfc0-bd69-4f05-b476-98fef4180ae9",
                        playerId: "85ab885a-b6c2-4e7b-a2bb-6601e7082b3b",
                        text: "Player 2's Test Response",
                        votes: 1,
                    },
                    {
                        id: "5a5450f8-0ae8-4e84-89d1-78155818f3d5",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "0344cfc0-bd69-4f05-b476-98fef4180ae9",
                        playerId: "7d301d9c-1cf0-4129-bde2-136e0f8e2f42",
                        text: "Player 1's Test Response",
                        votes: 0,
                    },
                    {
                        id: "064b1d47-bfa9-41bd-8067-37e2bf68f474",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "0344cfc0-bd69-4f05-b476-98fef4180ae9",
                        playerId: "85ab885a-b6c2-4e7b-a2bb-6601e7082b3b",
                        text: "Player 2's Test Response",
                        votes: 1,
                    },
                    {
                        id: "5a5450f8-0ae8-4e84-89d1-78155818f3d5",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "0344cfc0-bd69-4f05-b476-98fef4180ae9",
                        playerId: "7d301d9c-1cf0-4129-bde2-136e0f8e2f42",
                        text: "Player 1's Test Response",
                        votes: 0,
                    },
                    {
                        id: "064b1d47-bfa9-41bd-8067-37e2bf68f474",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "0344cfc0-bd69-4f05-b476-98fef4180ae9",
                        playerId: "85ab885a-b6c2-4e7b-a2bb-6601e7082b3b",
                        text: "Player 2's Test Response",
                        votes: 1,
                    },
                    {
                        id: "5a5450f8-0ae8-4e84-89d1-78155818f3d5",
                        gameId: "48ece274-9717-44d1-9982-20785fa22b31",
                        promptId: "0344cfc0-bd69-4f05-b476-98fef4180ae9",
                        playerId: "7d301d9c-1cf0-4129-bde2-136e0f8e2f42",
                        text: "Player 1's Test Response",
                        votes: 0,
                    },
                ],
            },
        ],
    };
}
