import React, { Component } from 'react';
import styles from './Logo.module.css';

export class Logo extends Component {
    render() {
        return (
            <div className={styles.logoContainer}>
                <h1 className={styles.logo + " " + this.props.className}>Quote me</h1>
                <h1 className={styles.logo + " " + this.props.className}>on that</h1>
            </div>
        );
    }
}