import React, { Component } from 'react';

export class QuitGameButton extends Component {
    render() {
        return (
            <div className="m-3">
                <button onClick={this.props.handleQuitGame} className={`quit-game-button button`}>Quit</button>
            </div>
        );
    }
}
