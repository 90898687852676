import React, { Component } from "react";
import styles from "./CriticSelectionPlayer.module.css";
import { Logo } from "../logo/Logo.js";
import { OpenQuote, CloseQuote } from "../quoteMarks/QuoteMarks.js";
import { QuitGameButton } from "../quitGame/QuitGameButton";

export class CriticSelectionPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedResponseId: null,
            hideBtn: true,
        };
    }

    render() {
        let prompt = this.props.currentPrompt;

        return (
            <>
                <div className={styles.content}>
                    <div className={`logo-row-player`}>
                        <Logo className="logo-sm" />
                        <QuitGameButton
                            handleQuitGame={this.props.handleQuitGame}
                        />
                    </div>
                    <div className={styles.prompt}>
                        <p>{prompt.text}</p>
                    </div>
                    <div className={styles.br}></div>
                    <h3 className={styles.title}>
                        Choose the player with the best response
                    </h3>
                    <div className={styles.br}></div>
                    <div className={styles.responsesContainer}>
                        {this.props.responses.map((response) => {
                            const isSelected = response.id === this.state.selectedResponseId;

                            return (
                                <div
                                    key={response.id}
                                    className={`${styles.response} ${isSelected ? styles.selectedResponse : ''}`}
                                    onClick={() => {
                                        this.setState({
                                            selectedResponseId: response.id,
                                            hideBtn: false,
                                        });
                                    }}
                                    value={response.id}
                                >
                                    <p className={styles.responseText}>
                                        <OpenQuote className={styles.quote} />
                                        {response.text}
                                        <CloseQuote className={styles.quote} />
                                    </p>
                                    <span className={styles.quoteBy}>
                                        -UNKNOWN
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <button
                    className={`${styles.submitBtn} button button-sm ${this.state.hideBtn === true ? ("hide") : ("")}`}
                    onClick={() =>
                        this.props.handleSubmitVote(
                            this.state.selectedResponseId
                        )
                    }
                >
                    Submit
                </button>
            </>
        );
    }
}
