import React, { Component } from "react";
import styles from "./WelcomePlayer.module.css";
import { OpenQuote, CloseQuote } from "../quoteMarks/QuoteMarks.js";

const nameCharLimit = 12;

export class WelcomePlayer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nameCharCount: nameCharLimit,
            nameVal: "",
            codeVal: this.props.gameCode,
            codeError: false,
            nameError: false,
            sessionPlayerName: ""
        };
    }

    componentDidMount() {
        // Access count value from session storage
        let playerName = sessionStorage.getItem("playerName");
        if (playerName != null) {
            this.setState({
                nameVal: playerName,
                sessionPlayerName: playerName,
            });
        }
    }

    handleNameInputChange = (event) => {
        const inputLength = event.target.value.length;
        let val = event.target.value;
        let charCount = nameCharLimit - inputLength;

        if (val.length > 0 &&
            !val.match(/^[ A-Za-z]+$/)) {
            return;
        }

        if (inputLength > nameCharLimit) {
            this.setState({
                nameError: false,
                nameCharCount: 0,
                nameVal: val.substring(0, nameCharLimit).toUpperCase()
            });
        } else {
            this.setState({
                nameError: false,
                nameCharCount: charCount,
                nameVal: val.toUpperCase()
            });
        }
    };

    handleCodeInputChange = (event) => {
        const inputLength = event.target.value.length;
        let val = event.target.value;

        if (val.length > 0 &&
            !val.match(/^[ A-Za-z]+$/)) {
            return;
        }

        if (inputLength <= 4) {
            this.setState({
                codeError: false,
                codeVal: val.toUpperCase()
            });
        } else {
            this.setState({
                codeError: false
            });
        }
        this.props.clearGameCodeError();
    }

    handleJoinGame = (event) => {
        event.preventDefault();
        let codeError = false;
        let nameError = false;
        // Check for code error
        if (this.state.codeVal.length !== 4) {
            codeError = true;
        }

        // Check for name error
        if (this.state.nameVal.length <= 0 ||
            this.state.nameVal.length > nameCharLimit) {
            nameError = true;
        }

        // If code or name error is true, set state
        if (codeError || nameError) {
            this.setState({
                codeError: codeError,
                nameError: nameError
            });
        } else {
            // Trigger join game here
            this.props.handleJoinGame(this.state.codeVal, this.state.nameVal);
        }
    };

    handleRejoinGame = (event) => {
        event.preventDefault();
        let codeError = false;
        
        // Check for code error
        if (this.state.codeVal.length !== 4) {
            codeError = true;
        }

        // If code error is true, set state
        if (codeError) {
            this.setState({
                codeError: codeError,
            });
        } else {
            // Trigger rejoin game here
            this.props.handleRejoinGame(this.state.codeVal);
        }
    };

    render() {
        return (
            <div className={styles.content}>
                <div className={styles.logoContainer}>
                    <h1 className={styles.logo}>
                        <OpenQuote />
                        Quote me
                    </h1>
                    <h1 className={`${styles.logo} ${styles.onThat}`}>
                        on that
                        <CloseQuote />
                    </h1>
                </div>
                <form onSubmit={this.handleJoinGame}>
                    <div className={styles.nameLabel}>
                        <label className={`${styles.label} ${this.state.nameError ? styles.errorLabel : ''}`}
                            htmlFor="name-input">
                            Name:
                        </label>
                        <span>{this.state.nameCharCount.toString()}</span>
                    </div>
                    <input
                        className={`${styles.input} ${this.state.nameError ? styles.error : ''}`}
                        id="name-input"
                        placeholder="Enter your name"
                        max={12}
                        onChange={this.handleNameInputChange}
                        autoComplete="off"
                        value={this.state.nameVal}
                    />
                    {
                        this.state.nameError ?
                            <div className={styles.errorLabel}>
                                Name is a required field and must be less than {nameCharLimit} characters long.
                            </div> :
                            <></>
                    }
                    <label
                        htmlFor="game-code-input"
                        className={`${styles.codeLabel} ${styles.label} ${this.state.codeError ? styles.errorLabel : ''}`}
                    >
                        Game Code:
                    </label>
                    <input
                        className={`${styles.input} ${this.state.codeError ? styles.error : ''}`}
                        id="game-code-input"
                        placeholder="ABCD"
                        autoComplete="off"
                        onChange={this.handleCodeInputChange}
                        value={this.state.codeVal}
                    />
                    {
                        this.state.codeError ?
                            <div className={styles.errorLabel}>
                                Game Code is a required field and must be 4 characters long.
                            </div> :
                            <></>
                    }
                    {
                        this.props.joinGameError ?
                            <div className={styles.errorLabel}>{this.props.joinGameError}</div> :
                            <></>
                    }
                    <button className={`${styles.submitBtn} button button-sm`} type="submit">
                        Play
                    </button>
                    {
                        this.props.connectionId ?
                            <>
                                <button className={`${styles.submitBtn} button button-sm`}
                                    onClick={this.handleRejoinGame}>
                                    Rejoin Game as {this.state.sessionPlayerName}
                                </button>
                            </> :
                            <></>
                    }
                </form>
                
            </div>
        );
    }
}
