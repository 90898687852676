import { Player } from "./components/player/Player.js";
import { Host } from "./components/host/Host";
import { PlayersJoining } from "./components/host/PlayersJoining.js";
import { PlayersResponding } from "./components/host/PlayersResponding.js";
import { CriticSelection } from "./components/host/CriticSelection.js";
import { EndScore } from "./components/host/EndScore.js";
import { WelcomePlayer } from "./components/player/WelcomePlayer.js";
import { PlayersJoiningPlayer } from "./components/player/PlayersJoiningPlayer.js";
import { AnsweringPrompt } from "./components/player/AnsweringPrompt.js";
import { Waiting } from "./components/player/Waiting.js";
import { CriticSelectionPlayer } from "./components/player/CriticSelectionPlayer.js";
import { EndPlayer } from "./components/player/EndPlayer.js";
import { getGameData } from "./data/gameData.js"
import { PlayerIconPicker } from "./components/playerIconPicker/PlayerIconPicker.js";

const game = getGameData();

const AppRoutes = [
  {
    index: true,
    element: <Player />
  },
  {
    path: '/host',
    element: <Host />
  },
  //Remove below routes before deployment
  {
      path: '/test1',
      element: <PlayersJoining gameCode="XYZZ" game={game} />
  },
  {
    path: '/test2',
    element: <PlayersResponding gameCode="XYZZ" game={game} />
  },
  {
      path: '/test3',
      element: <CriticSelection handleStartNextRound={() => {return 1 + 1}} game={game} responses={game.prompts[0].responses} currentPrompt={game.prompts[0]} />
  },
  {
    path: '/test4',
    element: <EndScore gameCode="XYZZ" game={game} />
  },
  {
    path: '/test5',
    element: <WelcomePlayer clearGameCodeError={() => {return 1 + 1}} gameCode="XYZZ" />
  },
  {
    path: '/test6',
    element: <PlayersJoiningPlayer player={game.players[0]} />
  },
  {
    path: '/test7', // as critic
    element: <AnsweringPrompt player={game.players[0]} prompt={game.prompts[0]} />
  },
  {
    path: '/test8', // not as critic
    element: <AnsweringPrompt player={game.players[0]} prompt={game.prompts[1]} />
  },
  {
    path: '/test9',
    element: <Waiting gameCode="XYZZ" />
  },
  {
    path: '/test10',
    element: <CriticSelectionPlayer currentPrompt={game.prompts[0]} responses={game.prompts[0].responses} gameCode="XYZZ" />
  },
  {
    path: '/test11',
    element: <EndPlayer gameCode="XYZZ" />
  },
  {
    path: '/test12',
    element: <Waiting waitingText={"Waiting for the critic to vote"} gameCode="XYZZ" />
  }
];

export default AppRoutes;

