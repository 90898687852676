import React, { Component } from "react";
import styles from "./PlayersJoiningPlayer.module.css";
import { PlayerIcon } from "../playerIcon/PlayerIcon.js";
import { Logo } from "../logo/Logo.js";
import { QuitGameButton } from "../quitGame/QuitGameButton";
import { PlayerIconPicker } from "../playerIconPicker/PlayerIconPicker.js";

export class PlayersJoiningPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customizeScreen: null,
        }
        this.playerIconPicker = React.createRef();
    }

    render() { 
        return (
            <div className={styles.content}>
                <div className={`logo-row-player`}>
                    <Logo />
                    <QuitGameButton handleQuitGame={this.props.handleQuitGame} />
                </div>
                <div className={styles.welcomePlayer}>
                    <h2 className={styles.urine}>You're in!</h2>
                    <div className="icon-lg">
                        <PlayerIcon player={this.props.player} />
                    </div>
                    <p className={styles.playerName}>{this.props.player.name}</p>
                </div>
                <div className={styles.btns}>
                    <button onClick={() => this.playerIconPicker.current.setOpen()} className={`${styles.customizeBtn} button button-sm`}>
                        <div className={styles.fakeIcon}>
                            <div className={styles.fakeHead}></div>
                            <div className={styles.fakeBody}></div>
                        </div>
                        Customize
                    </button>
                    <button onClick={this.props.handleStartGame} className={`${styles.startGameBtn} button button-sm`}>Start Game</button>
                </div>
                <PlayerIconPicker player={this.props.player} handleCustomizePlayer={this.props.handleCustomizePlayer} ref={this.playerIconPicker} />
            </div>
        );
    }
}