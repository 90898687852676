import React, { Component } from 'react';
import './GameCode.css';


export class GameCode extends Component {
   render() {
       return (
            <div className={`game-code ${this.props.className}`}>
                <p className="game-code-text">{this.props.gameCode}</p>
            </div>
        );
    }
}