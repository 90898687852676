import React, { Component } from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Welcome } from './Welcome.js';
import { PlayersJoining } from './PlayersJoining.js';
import { PlayersResponding } from './PlayersResponding.js';
import { CriticSelection } from './CriticSelection.js';
import { EndScore } from './EndScore.js';

export class Host extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gameCreated: false,
            connection: null,
            game: null,
            responses: null,
            currentPrompt: null
        };
        this.setConnection = this.setConnection.bind(this);
        this.setGame = this.setGame.bind(this);
        this.setResponses = this.setResponses.bind(this);
        this.setCurrentPrompt = this.setCurrentPrompt.bind(this);
    }

    componentDidMount() {
        window.document.addEventListener('click', () => {
            window.document.getElementById("audio").play();
        }, { once: true });
    }

    setConnection(connection) {
        this.setState({
            connection: connection
        });
    }

    setGame(game) {
        this.setState({
            game: game
        });
    }

    setResponses(responses) {
        this.setState({
            responses: responses
        });
    }

    setCurrentPrompt(currentPrompt) {
        this.setState({
            currentPrompt: currentPrompt
        });
    }

    createGame = async () => {
        try {
            const connection = new HubConnectionBuilder()
                .withUrl(process.env.REACT_APP_API_URL)
                .configureLogging(LogLevel.Information)
                .build();

            connection.on("UpdateGame", (game) => {
                this.setGame(game);
            });

            connection.on("UpdateResponses", (responses) => {
                this.setResponses(responses);
            });

            connection.on("UpdatePrompt", (currentPrompt) => {
                this.setCurrentPrompt(currentPrompt);
            });

            connection.onclose(e => {
                this.setConnection();
                this.setGame();
            });

            await connection.start();
            var game = await connection.invoke("CreateGameAsync");
            this.setConnection(connection);
            this.setGame(game);
        } catch (e) {
            console.log(e);
        }
    }

    startNextRound = async () => {
        try {
            await this.state.connection.invoke("StartNextRoundAsync", this.state.game.gameCode);
        } catch (e) {
            console.log(e);
        }
    }

    triggerCriticVoting = async () => {
        try {
            await this.state.connection.invoke("TriggerCriticVoting", this.state.game.gameCode);
        } catch (e) {
            console.log(e);
        }
    }

    closeConnection = async () => {
        try {
            await this.state.connection.stop();
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        let screen;
        if (!this.state.connection || !this.state.game) {
            screen = <Welcome handleCreateClicked={this.createGame} />
        }
        else {
            // Switch on the game states. Each screen displayed here should be a component.

            // QuoteMeOnThat.Enums.GameEnums.GameStates
            switch (this.state.game.gameState) {
                case 0: // Users Joining
                    screen = <PlayersJoining game={this.state.game} />
                    break;
                case 1: // Answering Prompts
                    screen = <PlayersResponding game={this.state.game} />
                    break;
                case 2: // Voting
                    screen = <CriticSelection game={this.state.game}
                        responses={this.state.responses}
                        currentPrompt={this.state.currentPrompt}
                        handleStartNextRound={this.startNextRound}
                        triggerCriticVoting={this.triggerCriticVoting} />
                    break;
                case 3: // GameOver
                    screen = <EndScore game={this.state.game} />
                    break;
                default:
                    screen = <h1>Error invalid game state</h1>
                    break;
            }
        }

        return (
            <div>
                <audio id="audio" loop autoPlay>
                    <source src="audio/once-in-paris.mp3" type="audio/mpeg" />
                    <p>Your browser doesn't support HTML5 audio.</p>
                </audio>
                {screen}
            </div>
        );
    }
}