import React, { Component } from 'react';
import { CloseQuote, OpenQuote } from '../quoteMarks/QuoteMarks.js';
import styles from './RandomQuotes.module.css';

export class RandomQuotes extends Component {
    //Will cycle random quotes eventually

    render() {
        return (
            <div className={styles.content}>
                <p className={styles.quoteText}>
                    <OpenQuote className={styles.quote} />
                    Merry Christmas!
                    <CloseQuote className={styles.quote} />
                </p>
                <p className={styles.quoteBy}>-Nick Lertola</p>
            </div>
        );
    }
}