import React, { Component } from 'react';
import styles from "./PlayerIconPicker.module.css";

export class PlayerIconPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            iconHead: this.props.player.headIconId,
            iconBody: this.props.player.bodyIconId,
        };
    }

    setOpen = () => {
        this.setState({
            isOpen: true,
        });
    }

    setClose = () => {
        this.setState({
            isOpen: false,
        });
    }

    setIconHead = (num) => {
        this.setState({
            iconHead: num,
        });
    }

    setIconBody = (num) => {
        this.setState({
            iconBody: num,
        });
    }

    handlePlayerIconSave = (event) => {
        event.preventDefault();
        this.props.handleCustomizePlayer(this.state.iconHead, this.state.iconBody);
        this.setClose();
    }

    render() { 
        if (this.state.isOpen) {
            return (
                <div className={styles.backgroundBlur}>
                    <div className={styles.content}>
                        <div className={styles.xBtnRow}>
                            <button className={`button button-sm ${styles.xBtn}`} onClick={this.setClose}>✕</button>
                        </div>
                        <form onSubmit={(event) => this.handlePlayerIconSave(event)} className={styles.form}>
                            <div>
                                <h2 className={styles.h2}>Select a head</h2>
                                <fieldset className={styles.headField}>
                                    <label onClick={() => this.setIconHead(0)}>
                                        <input type='radio' name='head' className={styles.radio} />
                                        <img className={styles.img} src='images/head-0.png' />
                                    </label>
                                    <label onClick={() => this.setIconHead(1)}>
                                        <input type='radio' name='head' className={styles.radio} />
                                        <img className={styles.img} src='images/head-1.png' />
                                    </label>
                                    <label onClick={() => this.setIconHead(2)}>
                                        <input type='radio' name='head' className={styles.radio} />
                                        <img className={styles.img} src='images/head-2.png' />
                                    </label>
                                    <label onClick={() => this.setIconHead(3)}>
                                        <input type='radio' name='head' className={styles.radio} />
                                        <img className={styles.img} src='images/head-3.png' />
                                    </label>
                                    <label onClick={() => this.setIconHead(4)}>
                                        <input type='radio' name='head' className={styles.radio} />
                                        <img className={styles.img} src='images/head-4.png' />
                                    </label>
                                    <label onClick={() => this.setIconHead(5)}>
                                        <input type='radio' name='head' className={styles.radio} />
                                        <img className={styles.img} src='images/head-5.png' />
                                    </label>
                                </fieldset>
                            </div>
                            <div>
                                <h2 className={styles.h2}>Select a body</h2>
                                <fieldset className={styles.bodyField}>
                                    <label onClick={() => this.setIconBody(0)}>
                                        <input type='radio' name='body' className={styles.radio} />
                                        <img className={styles.img} src='images/body-0.png' />
                                    </label>
                                    <label onClick={() => this.setIconBody(1)}>
                                        <input type='radio' name='body' className={styles.radio} />
                                        <img className={styles.img} src='images/body-1.png' />
                                    </label>
                                    <label onClick={() => this.setIconBody(2)}>
                                        <input type='radio' name='body' className={styles.radio} />
                                        <img className={styles.img} src='images/body-2.png' />
                                    </label>
                                    <label onClick={() => this.setIconBody(3)}>
                                        <input type='radio' name='body' className={styles.radio} />
                                        <img className={styles.img} src='images/body-3.png' />
                                    </label>
                                    <label onClick={() => this.setIconBody(4)}>
                                        <input type='radio' name='body' className={styles.radio} />
                                        <img className={styles.img} src='images/body-4.png' />
                                    </label>
                                    <label onClick={() => this.setIconBody(5)}>
                                        <input type='radio' name='body' className={styles.radio} />
                                        <img className={styles.img} src='images/body-5.png' />
                                    </label>
                                </fieldset>
                            </div>
                            <div className={styles.bottomRow}>
                                <div className={styles.previewIcon}>
                                    <img className={styles.img} src={`images/head-${this.state.iconHead}.png`} />
                                    <img className={styles.img} src={`images/body-${this.state.iconBody}.png`} />
                                </div>
                                <button className={`button button-sm ${styles.submitBtn}`} type='submit'>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            );
        } else {
            return (<></>);
        }
    }
}