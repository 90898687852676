import React, { Component } from 'react';
import './Timer.css';

export class Timer extends Component {
    //Will eventually be an animation
    
    render() { 
        return (
            <>
                <div className="timer-circle">
                    <div className="timer-hand"></div>
                </div>
            </>
        );
    }
}
