import React, { Component } from 'react';
import { CloseQuote, OpenQuote } from '../quoteMarks/QuoteMarks.js';
import './WelcomeAnimation.css';

export class WelcomeAnimation extends Component {
    //Will eventually be an animation

    render() {
        return (
            <div className="welcome-animation">
                <div className="large-box"><OpenQuote /><CloseQuote /></div>
                <div className="small-box"><OpenQuote /><CloseQuote /></div>
                <div className="small-box"><OpenQuote /><CloseQuote /></div>
                <div className="small-box"><OpenQuote /><CloseQuote /></div>
                <div className="small-box"><OpenQuote /><CloseQuote /></div>
            </div>
        );
    }
}