import React, { Component } from "react";
import { Logo } from "../logo/Logo.js";
import { OpenQuote, CloseQuote } from "../quoteMarks/QuoteMarks.js";
import { GameCode } from "../gameCode/GameCode.js";
import styles from "./CriticSelection.module.css";

let intervalId = 0;
const animateResponses = true;

export class CriticSelection extends Component {
    constructor(props) {
        super(props);
        let numResponses = this.props.responses.length;
        let answerClass;

        if (numResponses <= 6) {
            answerClass = styles.answerLg;
        } else if (numResponses <= 12) {
            answerClass = styles.answerMd;
        } else {
            answerClass = styles.answerSm;
        }

        this.state = {
            answerClass: answerClass,
            bigResponseId: null,
            animating: animateResponses,
            shownResponses: [],
        };

        if (animateResponses && numResponses <= 10) {
            this.animateAnswers();
        } else {
            this.state.shownResponses = this.props.responses;
            this.props.triggerCriticVoting();
        }
    }

    animateAnswers = () => {
        let currentIndex = 0;
        var self = this;
        intervalId = setInterval(function () {
            self.addAnswer(currentIndex, intervalId);
            currentIndex++;
        }, 4500);
    };

    addAnswer = (index) => {
        if (index >= this.props.responses.length) {
            clearInterval(intervalId);
            this.setState({
                bigResponse: null,
                animating: false,
            });

            // Tell hub it's time for the critic to vote.
            this.props.triggerCriticVoting();
            return;
        }
        let newResponse = this.props.responses[index];
        this.setState({
            bigResponse: newResponse,
        });
    };

    getPlayerName = (playerId) => {
        return this.props.game.players.filter(
            (player) => player.id == playerId
        )[0].name;
    };

    updateDimensions = () => {
        const logoRowHeight = document.getElementById("logo-row").offsetHeight;
        const answerRow = document.getElementById("answer-row");

        answerRow.style.height = `calc(100vh - ${
            logoRowHeight
        }px - 25px)`;
    };

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
        this.pageScroll();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    pageScroll() {
        document.getElementById("answer-row").scrollBy(1, 0);
        let scrolldelay = setTimeout(this.pageScroll, 10);
    }

    render() {
        let prompt = this.props.currentPrompt;
        let hasWinner = prompt.hasWinner;
        if (hasWinner) {
            setTimeout(() => this.props.handleStartNextRound(), 4000);
        }
        return (
            <div className={styles.content}>
                <div className={styles.logoRow} id="logo-row">
                    <Logo />
                    <div className={styles.prompt}>
                        <p>{prompt.text}</p>
                    </div>
                    <div className={styles.gameCodeRow} id="game-code-row">
                        <GameCode gameCode={this.props.game.gameCode} />
                    </div>
                </div>
                <div id="answer-row" className={styles.answerRow}>
                    <div className={styles.answerList}>
                        {
                            this.state.animating ?
                                this.state.bigResponse != null ?
                                    <div key={this.state.bigResponse?.id} className={`${styles.answer}
                                            ${this.state.answerClass}
                                            ${styles.newestAnswer}`}>
                                        <p className={styles.answerText}>
                                            <OpenQuote fontSize="24" />
                                            {this.state.bigResponse?.text}
                                            <CloseQuote fontSize="36" />
                                        </p>
                                        <p className={styles.answerBy}>
                                            -UNKNOWN
                                        </p>
                                    </div> :
                                    <></>
                                :
                            <>
                                {this.props.responses.map((response) => {
                                    return (
                                        <div
                                            key={response.id}
                                            className={`${styles.answer} ${this.state.answerClass} ${response.votes > 0 ? styles.winner : ''}`}>
                                            {response.votes > 0 ? (
                                                <img src="../images/award.png" className={styles.awardImg} alt="Winner" />
                                            ) : (
                                                <></>
                                            )}
                                            <p className={styles.answerText}>
                                                <OpenQuote fontSize="24" />
                                                {response.text}
                                                <CloseQuote fontSize="36" />
                                            </p>
                                            <p className={styles.answerBy}>
                                                {hasWinner
                                                    ? `-${this.getPlayerName(response.playerId)}`
                                                    : "-UNKNOWN"}
                                            </p>
                                        </div>
                                    )
                                })}
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
