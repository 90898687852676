import React, { Component } from 'react';
import './QuoteMarks.css';

export class OpenQuote extends Component {
    render() {
        return (
            <span className={this.props.className + " quote open"}>“</span>
        );
    }
}

export class CloseQuote extends Component {   
    render() {
        return (
            <span className={this.props.className + " quote close"}>”</span>
        )
    }
}