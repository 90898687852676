import React, { Component } from 'react';
import { Logo } from '../logo/Logo.js';
import { Timer } from '../timer/Timer.js';
import { PlayerIcon } from '../playerIcon/PlayerIcon.js';
import { GameCode } from '../gameCode/GameCode.js';
import styles from './PlayersResponding.module.css'

export class PlayersResponding extends Component {
    render() { 
        return (
            <div className={styles.content}>
                <div>
                    <Logo />
                </div>
                <div className={styles.timerPlayers}>
                    <Timer />
                    <div className={styles.playerList}>
                        {this.props.game.players.map((player) => {
                            return (
                                <div key={player.id} className={styles.player}>
                                    <div className="icon-med">
                                        <PlayerIcon player={player} />
                                    </div>
                                    <p className={styles.playerName}>{player.name}</p>
                                </div>
                            )
                        })}            
                    </div>
                </div>
                <div className={styles.gameCodeCol}>
                    <GameCode gameCode={this.props.game.gameCode} />
                </div>
            </div>  
        );
    }
}