import React, { Component } from 'react';
import { CloseQuote, OpenQuote } from '../quoteMarks/QuoteMarks.js';
import { WelcomeAnimation } from '../welcomeAnimation/WelcomeAnimation.js';
import './Welcome.css';

export class Welcome extends Component {
    constructor(props) {
        super(props);
        this.state = { gameCreated: false };
    }

    render() {
        return (
            <div id="container">
                <div className="logo">
                    <h1><OpenQuote />Quote me</h1>
                    <h1 className="on-that">on that<CloseQuote /></h1>
                </div>
                <button className='welcome-button button' onClick={this.props.handleCreateClicked}>Create Game</ button>
                <WelcomeAnimation />
            </div>
        );
    }
}