import React, { Component } from "react";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { WelcomePlayer } from "./WelcomePlayer.js";
import { PlayersJoiningPlayer } from "./PlayersJoiningPlayer.js";
import { AnsweringPrompt } from "./AnsweringPrompt.js";
import { Waiting } from "./Waiting.js";
import { CriticSelectionPlayer } from "./CriticSelectionPlayer.js";
import { EndPlayer } from "./EndPlayer.js";

export class Player extends Component {
    constructor(props) {
        super(props);
        this.state = {
            connection: null,
            connectionId: null,
            player: null,
            playerName: null,
            error: null,
            gameCode: null,
            currentPrompt: null,
            responses: null,
        };
        this.setConnection = this.setConnection.bind(this);
        this.setConnectionId = this.setConnectionId.bind(this);
        this.setPlayer = this.setPlayer.bind(this);
        this.setPlayerName = this.setPlayerName.bind(this);
        this.setError = this.setError.bind(this);
        this.setGameCode = this.setGameCode.bind(this);
        this.setCurrentPrompt = this.setCurrentPrompt.bind(this);
        this.setResponses = this.setResponses.bind(this);
    }

    componentDidMount() {
        // Access count value from session storage
        let connectionId = sessionStorage.getItem("connectionId");
        let gameCode = sessionStorage.getItem("gameCode");
        if (connectionId != null) {
            // Update state
            this.setConnectionId(connectionId);
        }
        if (gameCode != null) {
            this.setGameCode(gameCode);
        }

        // If connection and gamecode try to auto rejoin
        if (connectionId != null &&
            gameCode != null) {
            this.rejoinGame(gameCode);
        }
    }

    setConnection(connection) {
        this.setState({
            connection: connection,
        });
    }

    setConnectionId(connectionId) {
        this.setState({
            connectionId: connectionId,
        });
    }

    setPlayer(player) {
        if (player.playerState != 7) { // playerState 7 is game over.
            // Save connection id in session storage to use for rejoins.
            sessionStorage.setItem("connectionId", player.connectionId);
        } else {
            // Remove connection id and game code in session storage because game is over.
            sessionStorage.removeItem("connectionId");
            sessionStorage.removeItem("gameCode");
        }
        this.setState({
            player: player,
        });
    }

    setPlayerName(playerName) {
        this.setState({
            playerName: playerName,
        });
    }

    setGameCode(gameCode) {
        // Save game code in session storage to use for rejoins.
        sessionStorage.setItem("gameCode", gameCode);

        this.setState({
            gameCode: gameCode,
        });
    }

    setCurrentPrompt(currentPrompt) {
        this.setState({
            currentPrompt: currentPrompt,
        });
    }

    setResponses(responses) {
        this.setState({
            responses: responses,
        })
    }

    setError(error) {
        this.setState({
            error: error,
        })
    }

    connectToServer = async () => {
        try {
            const connection = new HubConnectionBuilder()
                .withUrl(process.env.REACT_APP_API_URL)
                .configureLogging(LogLevel.Information)
                .build();

            connection.on("UpdatePlayer", (player) => {
                this.setPlayer(player);
            });

            connection.on("UpdateResponses", (responses) => {
                this.setResponses(responses);
            });

            connection.on("UpdatePrompt", (currentPrompt) => {
                this.setCurrentPrompt(currentPrompt);
            });

            connection.on("SetError", (error) => {
                this.setError(error);
            })

            connection.on("NewGame", (gameCode) => {
                this.setGameCode(gameCode);
                this.closeConnection();
                this.setPlayer();
                this.setCurrentPrompt();
                this.setResponses();
                this.setError();
            })

            connection.onclose((e) => {
                this.setConnection();
                this.setPlayer();
                this.setGameCode();
                this.setCurrentPrompt();
                this.setResponses();
            });

            await connection.start();

            this.setConnection(connection);

            return connection;
        } catch (e) {
            console.log(e);
        }
    };

    submitResponse = async (answer) => {
        try {
            var player = await this.state.connection.invoke("SubmitResponseAsync", this.state.gameCode, this.state.currentPrompt.id, answer);
            this.setPlayer(player);
        } catch (e) {
            console.log(e);
        }
    }

    submitVote = async (responseId) => {
        try {
            await this.state.connection.invoke("VoteAsync", this.state.gameCode, this.state.currentPrompt.id, responseId);
        } catch (e) {
            console.log(e);
        }
    }

    joinGame = async (gameCode, playerName) => {
        var isJoiningGame = sessionStorage.getItem("joiningGame");

        if (isJoiningGame == "true") {
            return;
        }
        sessionStorage.setItem("joiningGame", true);
        // Save player name in session storage.
        sessionStorage.setItem("playerName", playerName);
        this.setPlayerName(playerName);

        var connection = await this.connectToServer();
        try {
            var player = await connection.invoke(
                "JoinGameAsync",
                gameCode,
                playerName,
                this.state.connectionId,
            );

            this.setPlayer(player);
            this.setGameCode(gameCode);
        } catch (e) {
            console.log(e);
        }
        sessionStorage.setItem("joiningGame", false);
    };

    rejoinGame = async (gameCode) => {
        var isJoiningGame = sessionStorage.getItem("joiningGame");

        if (isJoiningGame == "true") {
            return;
        }
        sessionStorage.setItem("joiningGame", true);
        var connection = this.state.connection;
        if (!connection) {
            // No connection, reconnect
            connection = await this.connectToServer();
        }

        try {
            var player = await connection.invoke(
                "RejoinGameAsync",
                gameCode,
                this.state.connectionId
            );

            this.setPlayer(player);
            this.setGameCode(gameCode);
        } catch (e) {
            console.log(e);
        }
        sessionStorage.setItem("joiningGame", false);
    };

    startGame = async () => {
        try {
            await this.state.connection.invoke("BeginGameAsync", this.state.gameCode);
        } catch (e) {
            console.log(e);
        }
    }

    restartGameSamePlayers = async () => {
        try {
            await this.state.connection.invoke("SamePlayersNewGameAsync", this.state.gameCode);
        } catch (e) {
            console.log(e);
        }
    }

    restartGameNewPlayers = async () => {
        try {
            await this.state.connection.invoke("NewPlayersNewGameAsync", this.state.gameCode);
        } catch (e) {
            console.log(e);
        }
    }

    customizePlayer = async (headIconId, bodyIconId) => {
        try {
            let player = await this.state.connection.invoke("CustomizePlayerDataAsync", headIconId, bodyIconId, this.state.gameCode);
            this.setPlayer(player);
        } catch (e) {
            console.log(e);
        }
    }

    closeConnection = async () => {
        try {
            await this.state.connection.stop();
            sessionStorage.removeItem("connectionId");
            sessionStorage.removeItem("gameCode");
        } catch (e) {
            console.log(e);
        }
    };

    render() {
        let screen;
        if (!this.state.connection || !this.state.player) {
            screen = (
                <WelcomePlayer
                    handleJoinGame={this.joinGame}
                    handleRejoinGame={this.rejoinGame}
                    connectionId={this.state.connectionId}
                    joinGameError={this.state.error}
                    clearGameCodeError={this.setError}
                    playerName={this.state.playerName}
                    gameCode={this.state.gameCode}
                />
            );
        } else {
            // Switch on the player states. Each screen displayed here should be a component.

            // QuoteMeOnThat.Enums.GameEnums.PlayerStates
            switch (this.state.player.playerState) {
                case 0: // JoinedWaiting
                    screen = <PlayersJoiningPlayer
                        handleCustomizePlayer={this.customizePlayer}
                        player={this.state.player}
                        handleStartGame={this.startGame}
                        handleQuitGame={this.closeConnection} />;
                    break;
                case 1: // AnsweringPrompt
                    screen = <AnsweringPrompt
                        player={this.state.player}
                        prompt={this.state.currentPrompt}
                        gameCode={this.state.gameCode}
                        handleSubmitResponse={this.submitResponse}
                        handleQuitGame={this.closeConnection} />;
                    break;
                case 2: // SubmittedPromptWaiting
                    screen = <Waiting
                        gameCode={this.state.gameCode}
                        handleQuitGame={this.closeConnection} />;
                    break;
                case 3: // CriticWaitingToVote
                    screen = <Waiting
                        waitingText={"Your options will appear here soon"}
                        gameCode={this.state.gameCode}
                        handleQuitGame={this.closeConnection} />;
                    break;
                case 4: // VotingWaiting
                    screen = <Waiting
                        waitingText={"Waiting for the critic to vote"}
                        gameCode={this.state.gameCode}
                        handleQuitGame={this.closeConnection} />;
                    break;
                case 5: // VoteCompletedWaiting
                    screen = <Waiting
                        waitingText={"A winning quote has been selected"}
                        gameCode={this.state.gameCode}
                        handleQuitGame={this.closeConnection} />;
                    break;
                case 6: // Voting
                    screen = (
                        <CriticSelectionPlayer
                            responses={this.state.responses}
                            gameCode={this.state.gameCode}
                            handleSubmitVote={this.submitVote}
                            handleQuitGame={this.closeConnection}
                            currentPrompt={this.state.currentPrompt}
                        />
                    );
                    break;
                case 7: // GameOver
                    screen = <EndPlayer
                        gameCode={this.state.gameCode}
                        handleQuitGame={this.closeConnection}
                        handleSamePlayers={this.restartGameSamePlayers}
                        handleNewPlayers={this.restartGameNewPlayers}
                    />;
                    break;
                default:
                    screen = <h1>Error invalid game state</h1>;
                    break;
            }
        }

        return <div>{screen}</div>;
    }
}
