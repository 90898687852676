import React, { Component } from 'react';
import "./WaitAnimation.css";

export class WaitAnimation extends Component {
    //Will eventually be an animation (and prolly SVG)

    render() { 
        return (
            <img src='https://static.thenounproject.com/png/4122458-200.png' className={this.props.className + " waitAnimation"}></img>
        );
    }
}