import React, { Component } from "react";
import styles from "./EndPlayer.module.css";
import { Logo } from "../logo/Logo.js";
import { GameCode } from "../gameCode/GameCode.js";
import { QuitGameButton } from "../quitGame/QuitGameButton";

export class EndPlayer extends Component {
    render() {
        return (
            <div className={styles.content}>
                <div className={`logo-row-player`}>
                    <Logo />
                    <QuitGameButton handleQuitGame={this.props.handleQuitGame} />
                </div>
                <div className={styles.gameOverContainer}>
                    <h2 className={styles.gameOver}>Game over</h2>
                    <h3>Play again with:</h3>
                    <button className={`${styles.btn} button button-sm`}
                        onClick={this.props.handleSamePlayers}>
                        Same Players
                    </button>
                    <button className={`${styles.btn} button button-sm`}
                        onClick={this.props.handleNewPlayers}>
                        New Players
                    </button>
                    <div className={styles.br} />
                    <button className={`${styles.btn} button button-sm`}
                        onClick={this.props.handleQuitGame}>
                        Exit Game
                    </button>
                </div>
                <div className={styles.gameCodeRow}>
                    <GameCode gameCode={this.props.gameCode} />
                </div>
            </div>
        );
    }
}
